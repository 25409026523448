import { ReturnManagement } from "../../../components/logistics/returnManagement/ReturnManagement"


export const ReturnManagementPage = () => {
    return (
        <>
            <ReturnManagement />
        </>
    )
}
